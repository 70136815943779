import React, { useEffect, useRef, useState } from "react";
import {
  ConfigData,
  DefaulSorting,
  dropdownConfig,
  statusConfig,
} from "./configData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import FileIcon from "../../../assets/common/ic_file.svg";
import DownloadIcon from "../../../assets/common/ic_download.svg";
import SearchIcon from "../../../assets/header/search.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import UploadIcon from "../../../assets/header/ic-upload.svg";
import { TableHeader } from "../../../components/reusableItems/header";
import { Reusable_table } from "../../../components/reusableItems/table";
import { Search } from "../../../components/reusableItems/search";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import {
  fetchAllClinics,
  deleteClinicById,
  getPresignedURL,
  uploadClinic,
  fetchExcelTemplate,
} from "../../../store/clinic";
import api from "../../../config/api";

import "./clinic.css";
import { useLocation, useNavigate } from "react-router";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import moment from "moment";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { fetchUserById } from "../../../store/userMgmt";
import { Button } from "@progress/kendo-react-buttons";
import { Filter } from "./filter";

export const ClinicListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const exportLink = useRef(null);
  const query = new URLSearchParams(searchQuery);
  const { loading, clinic_list } = useSelector((state) => state.clinic);
  const [tempData, setTempData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [popupErrorContent, setPopupErrorContent] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [filterValue, setFilterValue] = useState({
    hsc: "",
    // claimable: "",
  });

  const [templateUrl, setTemplateUrl] = useState("");
  const [file, setFile] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const fileInputRef = useRef();

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "clinic.access_clinic"
  );
  const uploadPermssion = checkPermission(
    userPermissions,
    "clinic.upload_clinic"
  );

  const getData = async (callback) => {
    setMainLoading(true);
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {
        dispatch(fetchAllClinics(filterValue)).then((res) => {
          setTempData(
            res.payload.data.map((item, index) => ({
              ...item,
              no: index + 1,
            }))
          );
          setMainLoading(false);
          setShouldFetch(false);
        });
      }
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const getExcelTemplate = async (callback) => {
    try {
      const res = await dispatch(fetchExcelTemplate());
      const templateUrl = res.payload.url;

      if (templateUrl) {
        // Open the URL in a new tab
        window.open(templateUrl, "_blank");
      } else {
        console.error("No URL found in the response payload.");
      }
    } catch (error) {
      console.error("Error fetching Excel template:", error);
    } finally {
    }
  };

  const handleCreateAction = () => {};

  const RefreshData = () => {
    getData();
  };

  const handleFilterAction = () => {
    setShowFilter(!showFilter);
  };

  const handleSearchAction = () => {
    setSearch(!search);
  };

  const handleFileChange = (e) => {
    console.log(e);

    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const icons = [
    {
      icon: FilterIcon,
      action: handleFilterAction,
    },
    {
      icon: RefreshIcon,
      action: RefreshData,
    },
    {
      icon: SearchIcon,
      action: handleSearchAction,
    },
  ];

  const actionTrigger = () => {
    setMainLoading(true);
    if (actionType === "Delete") {
      setShowDelete(false);
      dispatch(deleteClinicById(actionID)).then((res) => {
        getData();
      });
    }
  };

  const getFileExtension = (filename) => {
    // Get the last part of the filename after the last dot
    const lastDotIndex = filename.lastIndexOf(".");

    // If there is no dot or it's the first character, return an empty string
    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return "";
    }

    // Return the file extension
    return filename.substring(lastDotIndex + 1);
  };

  const uploadActionTrigger = async () => {
    if (file) {
      const extension_ = getFileExtension(file.name);
      const data = {
        extension: extension_,
      };
      setMainLoading(true);
      setShowUpload(false);

      dispatch(getPresignedURL(data)).then(async (res) => {
        console.log(res.payload.data.presigned_url);

        api.postDiff(res.payload.data.presigned_url, file).then((res1) => {
          console.log(res1);
          if (res1 === 200) {
            const params = {
              file: res.payload.data.key,
            };
            dispatch(uploadClinic(params)).then(async (res) => {
              console.log(res);
              if(res.payload.status_code === 200){
                fileInputRef.current.value = "";
                setFile(null);
                getData();
              }else{
                setPopupErrorContent(res.payload.Message)
                setShowPopup(true)
                setMainLoading(false)
                setFile(null)
                fileInputRef.current.value = "";
              }
            });
          } else {
            alert("An error occured while uploading file! Please try again!");
          }
        });
      });
    }
    setShowUpload(false);
  };

  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "Delete") {
      setActionID(id);
      setActionType(type);
      if (type === "Delete") {
        setShowDelete(true);
      }
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value != "") applySearch(value);
  };

  const handleClear = async () => {
    setSearchLoading(true);
    const data = clinic_list.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    setTempData(data);
    setSearchValue("");
    // Add a 1-second delay before setting loading to false
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setSearchLoading(false);
  };

  const searchFilterByMultipleColumn = (data, columns, searchValue) => {
    if (!searchValue) return data; // Return all data if searchValue is empty

    const lowerSearchValue = searchValue.toLowerCase();

    const tempData = [];

    data.forEach((item) => {
      const matches = columns.some((col) => {
        const itemValue = item[col.id];

        if (typeof itemValue === "string") {
          const valueToCheck = col.caseSensitive
            ? itemValue
            : itemValue.toLowerCase();
          return valueToCheck.includes(lowerSearchValue);
        }
        return false;
      });

      // Add only if it matches and is not already added
      if (matches && !tempData.find((i) => i.id === item.id)) {
        tempData.push(item);
      }
    });

    return tempData;
  };

  const applySearch = async (searchValue) => {
    // Check if searchValue is defined and not empty
    setSearchLoading(true);
    if (!searchValue) {
      const data = clinic_list.data.map((item, index) => ({
        ...item,
        no: index + 1,
      }));

      setTempData(data);
      console.log(data);

      setSearchLoading(false);
      return;
    }

    let searchColumn = [
      { id: "tpa", caseSensitive: false },
      { id: "region", caseSensitive: false },
      { id: "area", caseSensitive: false },
      { id: "clinic_name", caseSensitive: false },
      { id: "address", caseSensitive: false },
      { id: "remarks", caseSensitive: false },
      { id: "phone", caseSensitive: false },
    ];

    // Add index number to each item in data
    const data = clinic_list.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    // Call the search function with proper arguments
    const filtered = await searchFilterByMultipleColumn(
      data,
      searchColumn,
      searchValue
    );

    setTempData(filtered);
    setSearchLoading(false);
  };

  const FilterAction = () => {
    setShowFilter(false);

    getData();
  };

  const filterClearAction = async () => {
    setShowFilter(false);
    setFilterValue({
      ...filterValue,
      hsc: "",
      claimable: "",
    });
    setShouldFetch(true);
  };

  useEffect(() => {
    if (shouldFetch) {
      getData();
      setShouldFetch(false);
    }
  }, [filterValue, shouldFetch]);

  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <div className="reusable_header_main_box">
            <div className="reusable_header_title">Manage Panel GP Clinics</div>
            <div className="reusable_header_icon_main_box">
              {uploadPermssion && (
                <>
                  <div
                    key="upload_excel"
                    className="reusable_header_create_box upload-btn"
                    onClick={() => {
                      setShowUploadDialog(true);
                    }}
                  >
                    <img src={UploadIcon} />
                    Upload Excel
                  </div>
                </>
              )}
              {icons.map((i, id) => {
                if (i.buttonName) {
                  return (
                    <div
                      key={id}
                      className="reusable_header_create_box"
                      onClick={i.action}
                    >
                      {i.buttonName}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={id}
                      className="reusable_header_icon_box"
                      onClick={i.action}
                    >
                      <img src={i.icon} />
                    </div>
                  );
                }
              })}
            </div>
          </div>

          {showUploadDialog && (
            <Dialog
              title="Upload"
              onClose={() => {
                setShowUpload(false);
                setFile(null);
                setShowUploadDialog(false);
              }}
              open={showUploadDialog}
            >
              <div className="clinic_child_box_main_detail_items">
                <div className="clinic_child_box_main_detail_items_title tutorial_detail_center_item detail_padding_bottom">
                  Step 1: Download Panel GP Clinics Excel Template
                </div>
              </div>

              <div
                className="clinic_template_button"
                onClick={() => getExcelTemplate()}
              >
                <div className="child">
                  <img src={FileIcon} />
                  <div className="button-text">Excel file</div>
                </div>
                <img src={DownloadIcon} />
              </div>

              <div className="clinic_child_box_main_detail_items margin-top">
                <div className="clinic_child_box_main_detail_items_title tutorial_detail_center_item detail_padding_bottom">
                  Step 2: Upload Panel GP Clinics data Excel
                </div>
              </div>

              <div className="clinic_upload_layout">
                <div className="clinic_upload_layout_button">
                  <div className="button-text padding-top">Excel file</div>
                  <div
                    className="right-button-text padding-top"
                    onClick={() => fileInputRef.current.click()}
                  >
                    + Upload
                  </div>
                  <input
                    accept=".xlsx, .csv"
                    ref={fileInputRef}
                    key={"file"}
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e)} // Pass keyProp here
                  />
                </div>
                {file && (
                  <div className="slected-file-text ">
                    File Selected : {file.name}
                  </div>
                )}
              </div>

              <DialogActionsBar>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base clinic-submit-button"
                  onClick={() => setShowUpload(true)}
                  disabled={!file}
                >
                  Upload
                </button>
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base clinic-cancel-button"
                  onClick={() => {
                    setShowUpload(false);
                    setFile(null);
                    setShowUploadDialog(false);
                  }}
                >
                  Cancel
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showPopup && (
            <ShowDialog
              title={"Incorrect File Format."}
              content={popupErrorContent}
              // cancelContent={"Cancel"}
              cancelContent={"Please check the file you've uploaded. Ensure that it matches the template provided by the system."}
              cancelbtn={() => setShowPopup(false)}
              // confrinbtn={actionTrigger}
            />
          )}
          {showFilter && (
            <Filter
              setShowFilter={setShowFilter}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              FilterAction={FilterAction}
              filterClearAction={filterClearAction}
            />
          )}

          {showUpload && (
            <ShowDialog
              title={"Confirm Clinic List Upload"}
              content={
                "You are about to upload a new Clinic List, which will overwrite any existing records. Please confirm that you want to proceed with this upload. Are you sure you want to continue?"
              }
              cancelContent={"Cancel"}
              confrimContent={"Yes,Proceed with Upload"}
              mainBoxStyle={{ width: "480px" }}
              cancelbtn={() => {
                setShowUpload(false);
                setFile(null);
              }}
              confrinbtn={() => {
                uploadActionTrigger();
              }}
            />
          )}

          {loading || mainLoading || searchLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>
              <Reusable_table
                header={ConfigData}
                data={tempData}
                pagination={true}
                calculatePagi={true}
                pageChangeLoading={false}
                showRow={true}
                checkbox={false}
                defaultSorting={DefaulSorting}
                //   handleTableClick={handleTableClick}
                handleAction={handleTableClick}
                maxHeight={
                  search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
                }
                maxWidth={"calc(100vw - 240px)"}
              />
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
