// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken, getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { DR_SAFE_INCIDENT_ENDPOINT,DR_SAFE_GET_GROUPS, AUDIT_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  details_loading: false,
  remarks_loading: false,
  drSafeIncidentList: [],
  drSafeIncidentDetails: [],
  drSafeIncidentRemarks: [],
  error: null,
  details_error: null,
  remarks_error: null,
  route_groups: [],
  route_groups_loading: false,
  route_group_error: null,
  audit_data: [],
  auditLoading: false,
};

export const fetchAllDRSafeIncidents = createAsyncThunk(
  "dr_safe_incidents/fetchAllIncidents",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(`${DR_SAFE_INCIDENT_ENDPOINT}?submitter=all`, {}, token);
      let finalData = [];
      console.log("DR safe List Results:",results);
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        finalData.push(copied);
      }
      console.log("DR Safe Final Data: ", finalData);
      return finalData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSafeIncidentDetails = createAsyncThunk(
  "dr_safe_incidents/fetchAllIncidentDetails",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}`,
        {},
        token
      );
      let finalData = [];
      console.log("DR safe detail API Result:",results)
      let copied = results.data;
      for (const [key, value] of Object.entries(copied.status)) {
        copied["status"] = value;
      }
      finalData.push(copied);

      console.log("DR safe detail FinalData:",finalData)
      return finalData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const changeDRSafeIncidentStatus = createAsyncThunk(
  "dr_safe_incidents/changeIncidentStatus",
  async ({ case_ref_no, incidentStatus }, { rejectWithValue }) => {
    try {
      console.log("RefNo- ",case_ref_no,"Status- ",incidentStatus);
      const token = getCurrentLoggedInSessionToken();
      const data = {}
      const res = await api.post(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/${incidentStatus}`,
        JSON.stringify(data),
        token
      );
      console.log("Dr Safe Status Update api result-",res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSafeIncidentRemarks = createAsyncThunk(
  "dr_safe_incidents/fetchAllIncidentRemarks",
  async (case_ref_no, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSafeIncidentRemarks = createAsyncThunk(
  "dr_safe_incidents/saveIncidentRemarks",
  async ({ case_ref_no, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        JSON.stringify({
          description: description,
        }),
        token
      );
      const results = await api.get(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/remarks`,
        {},
        token
      );
      console.log("Save REMARKS: ", results.data);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSafeMarkAsDuplicate = createAsyncThunk(
  "dr_safe_incidents/saveIncidentMarkAsDuplicate",
  async ({ case_ref_no, currentParentCase, description }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      console.log("Duplicate API request:",case_ref_no,' ',currentParentCase,' ',description)
      const results = await api.post(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/duplicated`,
        JSON.stringify({
          parent_case_ref_no: currentParentCase,
          remark: description,
        }),
        token
      );
      console.log("Duplicated API result: ", results);
      return results;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);
// SHARE
export const saveDRSafeIncidentShare = createAsyncThunk(
  "dr_safe_incidents/saveIncidentShare",
  async ({ case_ref_no, emails }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      let email_list=emails.split(",")
      console.log("Share Api call: ",email_list);
      const res = await api.post(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/share`,
        JSON.stringify({
          emails: email_list,
        }),
        token
      );
      console.log("Share Result: ", res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const saveDRSafeIncidentGroup = createAsyncThunk(
  "dr_Safe_incidents/saveDRSafeIncidentGroup",
  async ({ case_ref_no, group_id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const res = await api.post(
        `${DR_SAFE_INCIDENT_ENDPOINT}/${case_ref_no}/route`,
        JSON.stringify({
          group_id: group_id,
        }),
        token
      );
      console.log("Route to Other Dep API result- ",res);
      return res;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllDRSafeRouteGroups = createAsyncThunk(
  "dr_Safe_incidents/fetchAllDRSafeRouteGroups",
  async (_, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(DR_SAFE_GET_GROUPS, {}, token);
      return results.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSafeSearchResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRSafeSearchResult",
  async ({caseRefNo, busRegNo, busService, incidentLocation, updatedBy}, { rejectWithValue }) => {
    try {
      console.log(caseRefNo, busRegNo, busService, incidentLocation, updatedBy)
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SAFE_INCIDENT_ENDPOINT}`,{submitter:all,case_ref_no:caseRefNo,bus_reg_no:busRegNo,bus_service:busService,incident_location:incidentLocation,updated_by:updatedBy},token);
      console.log("Search API result ",results)
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSafeFilterResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRSafeFilterResult",
  async ({status, updateon_startdate, updateon_enddate}, { rejectWithValue }) => {
    try {
      console.log(status, updateon_startdate, updateon_enddate);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SAFE_INCIDENT_ENDPOINT}`,{submitter:all,status:status,updated_on_start_date:updateon_startdate,updated_on_end_date:updateon_enddate},token);
      console.log("Filter API result ",results)
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDRSafeSortResult = createAsyncThunk(
  "dr_safe_incidents/fetchDRSafeSortResult",
  async ({sortby,orderby}, { rejectWithValue }) => {
    try {
      console.log(sortby,orderby);
      const token = getCurrentLoggedInSessionToken();
      let all="all";
      const results = await api.get(`${DR_SAFE_INCIDENT_ENDPOINT}`,{submitter:all,sort_by:sortby,order:orderby},token);
      console.log("SortBy API result ",results)
      let responseData = [];
      for (let i = 0; i < results.data.length; i++) {
        let copied = results.data[i];
        for (const [key, value] of Object.entries(copied.status)) {
          copied["status"] = value;
        }
        responseData.push(copied);
      }
      return responseData;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchDrSafeIncidentAudit = createAsyncThunk(
  "dr_safe_incidents/fetchDrSafeIncidentAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${AUDIT_ENDPOINT}?table_name=dr-safe-incident&source_id=${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const drSafeIncidentSlice = createSlice({
  name: "dr_safe_incidents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDRSafeIncidents.pending, (state) => {
        console.log("fetch all dr safe incidents pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllDRSafeIncidents.fulfilled, (state, action) => {
        console.log("fetch all dr safe incidents fulfilled: ", state, action);
        state.loading = false;
        state.drSafeIncidentList = action.payload;
      })
      .addCase(fetchAllDRSafeIncidents.rejected, (state, action) => {
        console.log("fetch all dr safe incidents rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllDRSafeIncidentDetails.pending, (state) => {
        console.log("fetch all dr safe incident details pending: ", state);
        state.details_loading = true;
      })
      .addCase(fetchAllDRSafeIncidentDetails.fulfilled, (state, action) => {
        console.log(
          "fetch all dr safe incident details fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSafeIncidentDetails = action.payload;
      })
      .addCase(fetchAllDRSafeIncidentDetails.rejected, (state, action) => {
        console.log(
          "fetch all dr safe incident details rejected: ",
          state,
          action
        );
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchAllDRSafeIncidentRemarks.pending, (state) => {
        console.log("fetch all dr safe incident remarks pending: ", state);
        state.remarks_loading = true;
      })
      .addCase(fetchAllDRSafeIncidentRemarks.fulfilled, (state, action) => {
        console.log(
          "fetch all dr safe incident remarks fulfilled: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.drSafeIncidentRemarks = action.payload;
      })
      .addCase(fetchAllDRSafeIncidentRemarks.rejected, (state, action) => {
        console.log(
          "fetch all dr safe incident remarks rejected: ",
          state,
          action
        );
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(saveDRSafeIncidentRemarks.pending, (state) => {
        console.log("save dr safe incident remarks pending: ", state);
        state.remarks_loading = true;
      })
      .addCase(saveDRSafeIncidentRemarks.fulfilled, (state, action) => {
        console.log("save dr safe incident remarks fulfilled: ", state, action);
        state.remarks_loading = false;
        state.drSafeIncidentRemarks = action.payload;
      })
      .addCase(saveDRSafeIncidentRemarks.rejected, (state, action) => {
        console.log("save dr safe incident remarks rejected: ", state, action);
        state.remarks_loading = false;
        state.remarks_error = action.error.message;
      })
      .addCase(saveDRSafeIncidentGroup.pending, (state) => {
        state.details_loading = true;
      })
      .addCase(saveDRSafeIncidentGroup.fulfilled, (state, action) => {
        state.details_loading = false;
        state.drSafeIncidentDetails = action.payload;
      })
      .addCase(saveDRSafeIncidentGroup.rejected, (state, action) => {
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchAllDRSafeRouteGroups.pending, (state) => {
        state.route_groups_loading = true;
      })
      .addCase(fetchAllDRSafeRouteGroups.fulfilled, (state, action) => {
        state.route_groups_loading = false;
        state.route_groups = action.payload;
      })
      .addCase(fetchAllDRSafeRouteGroups.rejected, (state, action) => {
        state.route_groups_loading = false;
        state.route_group_error = action.error.message;
      })
      .addCase(changeDRSafeIncidentStatus.pending, (state) => {
        console.log("change dr safe incident status pending: ", state);
        state.details_loading = true;
      })
      .addCase(changeDRSafeIncidentStatus.fulfilled, (state, action) => {
        console.log(
          "change dr safe incident status fulfilled: ",
          state,
          action
        );
        state.details_loading = false;
        state.drSafeIncidentDetails = action.payload;
      })
      .addCase(changeDRSafeIncidentStatus.rejected, (state, action) => {
        console.log("change dr safe incident status rejected: ", state, action);
        state.details_loading = false;
        state.details_error = action.error.message;
      })
      .addCase(fetchDrSafeIncidentAudit.pending, (state) => {
        state.auditLoading = true;
      })
      .addCase(fetchDrSafeIncidentAudit.fulfilled, (state, action) => {
        state.auditLoading = false;
        state.audit_data = action.payload.data;
      })
      .addCase(fetchDrSafeIncidentAudit.rejected, (state, action) => {
        state.auditLoading = false;
        state.details_error = action.error.message;
      })
  },
});

const drSafeIncidentReducer = drSafeIncidentSlice.reducer;
export default drSafeIncidentReducer;
