import React, { useEffect, useRef, useState } from "react";
import {
  ConfigData,
  DefaulSorting,
  dropdownConfig,
  statusConfig,
} from "./configData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import ExportIcon from "../../assets/header/export.svg";
import RefreshIcon from "../../assets/header/refresh.svg";
import { TableHeader } from "../../components/reusableItems/header";
import { Reusable_table } from "../../components/reusableItems/table";
import {
  fetchAllPersona,
} from "../../store/persona_configuration";
import "./persona_configuration.css";
import { useLocation, useNavigate } from "react-router";
import { PermissionDeninedPage } from "../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import moment from "moment";
import { ShowDialog } from "../../components/reusableItems/dialog";
import { fetchUserById } from "../../store/userMgmt";
import {
  ADMIN_TOOLS_TUTORIAL_DETAILS,
  ADMIN_TOOLS_TUTORIAL_ADD,
  PERSONA_CONFIGURATION_DETAILS,
  PERSONA_CONFIGURATION_LIST,
  PERSONA_CONFIGURATION_UPDATE,
} from "../../routes";

export const PersonaListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const { loading, persona_list } = useSelector(
    (state) => state.persona
  );
  const [actionType, setActionType] = useState("");
  const [tempData, setTempData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "admin_tools.access_persona"
  );
  const updatePermssion = checkPermission(
    userPermissions,
    "admin_tools.add_edit_persona"
  );

  const getData = async (callback) => {
    setMainLoading(true);
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {
        dispatch(fetchAllPersona(query)).then((res) => {          
          setTempData(
            res.payload.data.map((item, index) => ({
              ...item,
              persona_dept_codes: item.persona_dept_codes.join(", "),
              persona_sect_codes: item.persona_sect_codes.join(", "),
              persona_pay_areas: item.persona_pay_areas.join(", "),
            }))
          );    
          setMainLoading(false);
      
        });
        
      }
    );
  };
  useEffect(() => {
    getData();
  }, []);


  const RefreshData = () => {
    getData();
  };

  const ExpprtData = () => {
    //getData();
  };

  const icons = [
    
  ];

  const actionTrigger = () => {
    setMainLoading(true);
    // if (actionType === "Delete") {
    //   setShowDelete(false);
    //   const data = {
    //     id: actionID,
    //   };
    //   dispatch(deleteTutorialById(data)).then((res) => {
    //     getData();
    //   });
    // }
  };
  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "Update") {
      navigate(`${PERSONA_CONFIGURATION_UPDATE}?id=${id}`);
    }else  if (type === "View Details") {
      navigate(`${PERSONA_CONFIGURATION_DETAILS}?id=${id}`);
    } 
  };

  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <TableHeader title={"Persona Configuration"} icons={icons} />

          {loading  || mainLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              checkbox={false}
              defaultSorting={DefaulSorting}
              //   handleTableClick={handleTableClick}
              handleAction={handleTableClick}
              maxHeight={"calc(-221px + 100vh)"}
              maxWidth={"calc(100vw - 240px)"}
              
            />
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
