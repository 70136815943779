import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Stepper } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

import {
    checkPermission,
    getUserPermission,
} from "../../config/permission_helper";
import { CustomStep } from "../../components/common/custom_steps";
import { PermissionDeninedPage } from "../../components/reusableItems/permission_denined";
import { Confirmation } from "../../components/dialog/confirmation";
import { ShowDialog } from "../../components/reusableItems/dialog";
import "./persona_configuration.css";
import moment from "moment";

import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../assets/common/arrao-right.svg";
import { fetchPersonaAudit, fetchPersonaDetailById } from "../../store/persona_configuration";
import { ADMIN_TOOLS_TUTORIAL_LIST, PERSONA_CONFIGURATION_LIST, PERSONA_CONFIGURATION_UPDATE } from "../../routes";

export const PersonaDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { search } = useLocation();
    const [detailLoading, setDetailLoading] = useState(true);
    const query = new URLSearchParams(search);
    const id = query.get("id");
    const {
        detail_data,
        detail_loading,
        detail_error,
        audit_data,
        audit_loading,
    } = useSelector((state) => state.persona);
    const userPermissions = getUserPermission();
    const viewPermssion = checkPermission(
        userPermissions,
        "admin_tools.access_persona"
    );

    const editPermission = checkPermission(
        userPermissions,
        "admin_tools.add_edit_persona"
    );

    const getData = async () => {
        dispatch(fetchPersonaDetailById(id)).then((res) => {
            setDetailLoading(false);
            dispatch(fetchPersonaAudit(id));
        });
    };

    useEffect(() => {
        getData();
    }, [id]);


    const RenderRow = ({ title, value }) => {
        return (
            <>
                {(
                    <>
                        <div className="persona_detail_child_box_main_detail_items">
                            <div className="persona_detail_child_box_main_detail_items_title persona_detail_center_item detail_padding_bottom">
                                {title}
                            </div>
                            <div className="persona_detail_child_box_main_detail_items_splitter" />
                            <div className="persona_detail_center_item persona_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                                {value != '' ? value : ' - ' }
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {viewPermssion ? (
                <>
                    {detail_loading || detailLoading ? (
                        <Loader
                            type="converging-spinner"
                            style={{
                                width: "100%",
                                height: "calc(100% - 75px)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#ffffff4d",
                                position: "absolute",
                                zIndex: "999",
                            }}
                        />
                    ) : (
                        <div className="persona_detail_main_box">
                            <div className="persona_detail_child_box">
                                <div className="persona_detail_left_box">
                                    <div
                                        className="persona_detail_header"
                                    //remove style if autosave needs to show
                                    // style={{ minHeight: "50px" }}
                                    >
                                        <div
                                            className="persona_detail_back"
                                            onClick={() => navigate(PERSONA_CONFIGURATION_LIST)}
                                        >
                                            <span className="k-icon k-font-icon k-i-arrow-chevron-left" />
                                            Back
                                        </div>


                                    </div>

                                    <div className="persona_detail_child_box_main_detail_box">
                                        <div>
                                            <RenderRow title={"Persona Name"} value={detail_data?.name  ?? ''} />
                                            <RenderRow title={"Personal Area/ DeptCode"} value={detail_data?.persona_dept_codes.join(", ") ?? ''} />
                                            <RenderRow title={"Personal Sub Area/ SectCode"} value={detail_data?.persona_sect_codes.join(", ") ?? ''} />
                                            <RenderRow title={"PayArea"} value={detail_data?.persona_pay_areas.join(", ") ?? ''} />
                                        </div>
                                    </div>
                                </div>
                                <div className="persona_detail_right_box">
                                    <div className="persona_detail_right_box_action_main_box">
                                        <div className="persona_detail_right_box_action_title">
                                            Actions
                                        </div>
                                        {editPermission && (
                                            <div
                                                className="persona_detail_right_box_action_delete"
                                                onClick={() => {
                                                    navigate(
                                                        `${PERSONA_CONFIGURATION_UPDATE}?id=${id}`
                                                    );
                                                }}
                                            >
                                                Update &#x2192;
                                            </div>
                                        )}
                                    </div>
                                    <div className="persona_detail_right_box_action_main_box">
                                        <div className="persona_detail_right_box_action_title">
                                            Activity Trail
                                        </div>
                                        <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                                            {audit_loading ? (
                                                <Loader
                                                    type="converging-spinner"
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        background: "#ffffff4d",
                                                    }}
                                                />
                                            ) : (
                                                <Stepper
                                                    orientation={"vertical"}
                                                    items={audit_data}
                                                    item={CustomStep}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <PermissionDeninedPage
                    height={"calc(100% - 76px)"}
                    title={"Sorry, You don't have permission to access this page!"}
                />
            )}
        </>
    );
};
