import React, { useEffect, useState } from "react";
import "./runtime_feedback.css";
import { TableHeader } from "../../components/reusableItems/header";
import RefreshIcon from "../../assets/common/RefreshIcon.svg";
import { Filter } from "./filter";
import {
  Reusable_table,
  role_permission_checker,
} from "../../components/reusableItems/table";
import { ConfigData } from "./ConfigData";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRuntimeBusServiceNo,
  fetchRuntimeListing,
  fetchRuntimeStatusUpdate,
} from "../../store/runtime/runtime";
import { PermissionDeninedPage } from "../../components/reusableItems/permission_denined";
import { Loader } from "@progress/kendo-react-indicators";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import { useNavigate } from "react-router";
import { RUNTIME_FEEDBACK_DETAILS } from "../../routes";
import { ShowDialog } from "../../components/reusableItems/dialog";

export const Runtime_Feedback = () => {
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "runtime_feedback.access_runtime_feedback_list"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, listing_data, bus_service_data, serviceLoading } =
    useSelector((state) => state.runtime);
  const [exportIDS, setExportIDS] = useState([]);
  const [statusIDS, setStatusIDS] = useState([]);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [filterData, setFilterData] = useState({
    reference_no: "",
    service_no: "",
    bc_no: "",
    request: "",
    current_status: "",
  });
  const [updateStatusDetail, setUpdateStatusDetail] = useState({
    id: "",
    status: "",
  });
  let delay;

  useEffect(() => {
    dispatch(fetchRuntimeBusServiceNo());
    dispatch(fetchRuntimeListing());
  }, []);

  const rowCheckboxClicked = async (type, id) => {
    let tempId = [...exportIDS];
    let tempStatusID = [...statusIDS];
    if (type === "header") {
      let temp = [];
      let tempStatus = [];
      await id.map((i) => {
        temp.push(i.id);
        tempStatus.push({ id: i.id, status: "" });
      });
      const filtered = temp.filter((i) => tempId.find((j) => j === i) !== i);
      const filteredStatus = tempStatus.filter(
        (i) => tempStatusID.find((j) => j.id === i.id)?.id !== i.id
      );
      if (filtered.length === 0) {
        const deletedFilter = tempId.filter(
          (i) => temp.find((j) => j === i) !== i
        );
        const deletedFilterStatus = tempStatusID.filter(
          (i) => tempStatus.find((j) => j.id === i.id)?.id !== i.id
        );
        tempId = [...deletedFilter];
        tempStatusID = [...deletedFilterStatus];
      } else {
        tempId = [...tempId, ...filtered];
        tempStatusID = [...tempStatusID, ...filteredStatus];
      }
    } else {
      if (exportIDS.find((i) => i === id)) {
        const filtered = tempId.filter((i) => i !== id);
        const filteredStatus = tempStatusID.filter((i) => i.id !== id);
        tempId = filtered;
        tempStatusID = filteredStatus;
      } else {
        tempId.push(id);
        tempStatusID.push({ id: id, status: "" });
      }
    }
    setExportIDS(tempId);
    setStatusIDS(tempStatusID);
  };

  const handleStatusChange = (value, id) => {
    let tempId = [...exportIDS];
    let tempStatusID = [...statusIDS];
    console.log(value, id);
    const id_finder = tempId.find((i) => i === id);
    if (id_finder) {
      tempStatusID.find((i) => i.id === id).status = value;
    } else {
      tempId.push(id);
      tempStatusID.push({ id: id, status: value });
    }
    setExportIDS(tempId);
    setStatusIDS(tempStatusID);
  };

  console.log(listing_data);

  const handleTableClick = (value, id) => {
    console.log(value, id);
    if (value === "View Details") {
      navigate(`${RUNTIME_FEEDBACK_DETAILS}?id=${id}`);
    } else {
      let status = listing_data.find((i) => i.id === id).status;
      setUpdateStatusDetail({
        ...updateStatusDetail,
        id: id,
        status: status,
      });
      setShowUpdateStatus(true);
    }
  };

  const updateStatus = () => {
    setShowUpdateStatus(false);
    let params = [{ id: updateStatusDetail.id, status: tempStatus }];
    dispatch(fetchRuntimeStatusUpdate(params)).then((res) => {
      if (res.payload.status_code === 200) {
        dispatch(fetchRuntimeListing(getFilterData()));
      } else {
        alert(res.payload.message);
      }
    });
  };

  const getDropdownData = () => {
    const validator = role_permission_checker({
      permission: ["runtime_feedback.update_runtime_feedback_status"],
      // role: ["Validator"],
    });
    // const system_owner = role_permission_checker({
    //   permission: [
    //     "runtime_feedback.update_valid_invalid_status_runtime_feedback",
    //   ],
    //   // role: ["System Owner"],
    // });

    if (
      Object.values(validator).every((item) => item === true) &&
      updateStatusDetail.status === "Submitted"
    ) {
      return ["Invalid", "Valid"];
    }
    // else if (
    //   Object.values(system_owner).every((item) => item === true) &&
    //   updateStatusDetail.status !== "Submitted"
    // ) {
    //   return ["Review in Progress", "Not Supported"];
    // }
  };

  const getFilterData = () => {
    let params = {};
    if (filterData.reference_no !== "") {
      params = {
        ...params,
        reference_no: filterData.reference_no,
      };
    }
    if (filterData.service_no !== "") {
      params = {
        ...params,
        service_no: filterData.service_no,
      };
    }

    if (filterData.bc_no !== "") {
      params = {
        ...params,
        bc_no: filterData.bc_no,
      };
    }

    if (filterData.request !== "") {
      params = {
        ...params,
        request: filterData.request,
      };
    }

    if (filterData.current_status !== "") {
      params = {
        ...params,
        current_status: filterData.current_status,
      };
    }
    return params;
  };

  useEffect(() => {
    delay = setTimeout(() => {
      dispatch(fetchRuntimeListing(getFilterData()));
    }, 500);
    return () => clearTimeout(delay);
  }, [filterData]);

  const handleChangeStatus = () => {
    setExportIDS([]);
    setStatusIDS([]);
    dispatch(fetchRuntimeStatusUpdate(statusIDS)).then((res) => {
      if (res.payload.status_code === 200) {
        dispatch(fetchRuntimeListing(getFilterData()));
      } else {
        alert(res.payload.message);
      }
    });
  };

  const handleRefresh = () => {
    dispatch(fetchRuntimeBusServiceNo());
    dispatch(fetchRuntimeListing());
  }

  const icons = [
    {
      icon: RefreshIcon,
      action: handleRefresh
    },
  ];

  return (
    <>
      {viewPermssion ? (
        <div>
          {showUpdateStatus && (
            <ShowDialog
              cancelbtn={() => {
                setShowUpdateStatus(false);
                setTempStatus("");
                setUpdateStatusDetail({ id: "", status: "" });
              }}
              title={"Update Status"}
              dropdownContent={"Set status"}
              dropdown={true}
              cancelContent={"Cancel"}
              confrimContent={"Update"}
              confrinbtn={updateStatus}
              dropdownData={getDropdownData()}
              dropdownOnChange={(e) => setTempStatus(e)}
              dropdownValue={tempStatus}
            />
          )}
          <TableHeader title={"Runtime Feedback List"} icons={icons} />
          <Filter
            service_data={bus_service_data}
            serviceLoading={serviceLoading}
            filterData={filterData}
            setFilterData={setFilterData}
            handleChangeStatus={handleChangeStatus}
          />
          {loading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 262px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={listing_data}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              // checkbox={
              //   role_permission_checker({
              //     permission: [
              //       "runtime_feedback.update_valid_invalid_status_runtime_feedback",
              //     ],
              //     // role: ["System Owner"],
              //   }).permission === true
              //     ? true
              //     : false
              // }
              defaultSorting={false}
              // rowCheckboxClicked={
              //   role_permission_checker({
              //     permission: [
              //       "runtime_feedback.update_valid_invalid_status_runtime_feedback",
              //     ],
              //     // role: ["System Owner"],
              //   }).permission === true && rowCheckboxClicked
              // }
              // checkedItem={exportIDS}
              // checkedItemData={statusIDS}
              // handleStatusChange={handleStatusChange}
              handleAction={handleTableClick}
              maxHeight={"calc(100vh - 313px)"}
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
