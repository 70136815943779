import React, { useState, useEffect } from "react";

import "./persona_configuration.css";

const SelectorButton = ({ codes, selectedCodes, onSelectionChange }) => {
    const [currentSelection, setCurrentSelection] = useState(selectedCodes);

    const toggleSelection = (code) => {
        const updatedSelection = currentSelection.includes(code)
            ? currentSelection.filter((item) => item !== code) // Deselect
            : [...currentSelection, code]; // Select

        setCurrentSelection(updatedSelection);
        onSelectionChange(updatedSelection); // Pass selected data to parent
    };

    useEffect(() => {
        setCurrentSelection([...selectedCodes]); // Ensure re-render
    }, [selectedCodes]);

    return (
        <div className="persona-dept-container">
            {codes.map((code) => (                
                <button
                    key={code}
                    className={`selector-button ${currentSelection.includes(code) ? "selected" : ""}`}
                    onClick={() => toggleSelection(code)}
                >
                    {code}
                </button>
            ))}
        </div>
    );
};

export default SelectorButton;
