// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Stepper } from "@progress/kendo-react-layout";
// components
import DrStepper from "../../../../components/drStepper/dr_stepper";
import { CustomStep } from "../../../../components/common/custom_steps";

const DrSecureDetailsAudits = ({ loading, audits }) => {
  return (
    <div className="dr_secure_detail_right_box_action_main_box">
    <div className="dr_secure_detail_right_box_action_title">
      Activity Trail
    </div>
    <div className={audits.length <= 1 && "audit_bar_hide"}>
      {loading ? (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
          }}
        />
      ) : (
        <Stepper
          orientation={"vertical"}
          items={audits}
          item={CustomStep}
        />
      )}
    </div>
  </div>
  );
};

export default DrSecureDetailsAudits;
