const userGroupKey = "user_group";

export const saveUserGroup = (result) => {
  localStorage.setItem(userGroupKey, JSON.stringify(result));
};

export const getUserGroup = () => {
  return JSON.parse(localStorage.getItem(userGroupKey));
};

export const checkUserGroup = (userGroup, requiredGroup) => {
  if (
    userGroup.find(i => i.group_name === requiredGroup) !== undefined
  ) {
    return true;
  }
  return false;
};