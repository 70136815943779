import React, { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./persona_configuration.css";
import BackIcon from "../../assets/communication/Back.svg";
import CloseIcon from "../../assets/header/ic-close-white.svg";
import { PERSONA_CONFIGURATION_LIST } from "../../routes";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import api from "../../config/api";
import { PermissionDeninedPage } from "../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import { ShowDialog } from "../../components/reusableItems/dialog";

import {
  createAndUpdatePersona,
  fetchAllPersona,
  fetchPersonaDept,
  fetchPersonaDetailById,
  fetchPersonaEmpSGRP,
  fetchPersonaPayArea,
  fetchPersonaSect,
} from "../../store/persona_configuration";
import SelectorButton from "./selector_button";
import { fetchAllPermissions } from "../../store/groupMgmt";

export const UpdatePersona = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedId, setSelectedId] = useState(id || "");
  const [selectedName, setSelectedName] = useState("");
  const [isDefaultPersona, setIsDefaultPersona] = useState(false);

  const [personaDepts, setPersonaDepts] = useState([]);
  const [selectPersonaDepts, setSelectPersonaDepts] = useState([]);

  const [personaSect, setPersonaSect] = useState([]);
  const [selectPersonaSect, setSelectPersonaSect] = useState([]);

  const [personaEmpSGRP, setPersonaEmpSGRP] = useState([]);
  const [selectPersonaEmpSGRP, setSelectPersonaEmpSGRP] = useState([]);

  const [personaPayArea, setPersonaPayArea] = useState([]);
  const [selectPersonaPayArea, setSelectPersonaPayArea] = useState([]);

  const {
    persona_dept,
    persona_sect,
    persona_emp_sgrp,
    persona_pay_area,
    detail_data,
    loading,
    detail_loading,
    persona_list,
  } = useSelector((state) => state.persona);
  const userPermissions = getUserPermission();

  const updatePermssion = checkPermission(
    userPermissions,
    "admin_tools.add_edit_persona"
  );

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const { all_permissions_loading, all_permissions } = useSelector(
    (state) => state.groupMgmt
  );

  useEffect(() => {
    dispatch(fetchAllPermissions());
  }, []);

  const loadData = async () => {
    dispatch(fetchAllPersona()).then((res) => {
      dispatch(fetchPersonaDept()).then((res) => {
        console.log(res.payload.data);

        const deptArray = res.payload.data.map((item) => item.dept_code);

        setPersonaDepts(deptArray);
        dispatch(fetchPersonaSect()).then((res1) => {
          const sectArray = res1.payload.data.map((item) => item.sect_code);

          setPersonaSect(sectArray);
          dispatch(fetchPersonaEmpSGRP()).then((res2) => {
            const empSgrpArray = res2.payload.data.map((item) => item.emp_sgrp);
            setPersonaEmpSGRP(empSgrpArray);
            dispatch(fetchPersonaPayArea()).then((res3) => {
              const payArea = res3.payload.data;
              setPersonaPayArea(payArea);
            });
          });
        });
      });
    });
  };

  const getData = async (personaId) => {
    dispatch(fetchPersonaDetailById(personaId)).then((res) => {
      console.log(res.payload?.persona_pay_areas);
      setSelectedName(res.payload?.name);
      setIsDefaultPersona(res.payload?.is_default_persona);
      setSelectPersonaDepts([...res.payload?.persona_dept_codes]);
      setSelectPersonaSect([...res.payload?.persona_sect_codes]);
      setSelectPersonaPayArea([...res.payload?.persona_pay_areas]);
      setSelectPersonaEmpSGRP([...res.payload?.persona_emp_sgrps]);
      setSelectedPermissions([...res.payload?.perm]);
      setRefreshLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    getData(id);
  }, []);

  const uploadData = async () => {
    setSubmitLoading(true);
    const params = {
      persona_name: selectedName,
      persona_perms: selectedPermissions,
      is_default_persona: isDefaultPersona,
      persona_dept_codes: selectPersonaDepts,
      persona_sect_codes: selectPersonaSect,
      persona_pay_areas: selectPersonaPayArea,
      persona_emp_sgrps: selectPersonaEmpSGRP,
    };

    dispatch(createAndUpdatePersona({ data: params, id: selectedId })).then(
      async (res) => {
        console.log(res.payload);

        if (res.payload.status === "success") {
          setSubmitLoading(false);
          navigate(PERSONA_CONFIGURATION_LIST);
        } else {
          setErrorMessage(res.payload.message);
          setSubmitLoading(false);
        }
      }
    );
  };

  const handleCancel = async () => {
    navigate(PERSONA_CONFIGURATION_LIST);
  };

  const handleSubmitRecord = () => {
    uploadData();
  };

  const handleSelection = (id) => {
    setSelectedId(id);
    setRefreshLoading(true);
    query.set("id", id);
    navigate(`?${query.toString()}`);
    getData(id);
  };

  const handleDeptSelectionChange = (selectedItems) => {
    setSelectPersonaDepts(selectedItems);
  };

  const handleSectSelectionChange = (selectedItems) => {
    setSelectPersonaSect(selectedItems);
  };

  const handleEmpSGRPSelectionChange = (selectedItems) => {
    setSelectPersonaEmpSGRP(selectedItems);
  };

  const handlePayAreaSelectionChange = (selectedItems) => {
    setSelectPersonaPayArea(selectedItems);
  };

  const handleSelected = (e, item) => {
    let updatedList = [...selectedPermissions];
    if (e.target.value) {
      updatedList.push(item.name);
    } else {
      updatedList = updatedList.filter((x) => x !== item.name);
    }
    setSelectedPermissions(updatedList);
  };

  const isChecked = (item) => {
    return selectedPermissions.includes(item.name);
  };

  return (
    <>
      {updatePermssion ? (
        <div style={{ height: "calc(100% - 95px)" }}>
          {refreshLoading ||
            (submitLoading && (
              <Loader
                type="converging-spinner"
                style={{
                  width: "100%",
                  height: "calc(100% - 95px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#ffffff4d",
                  position: "absolute",
                  zIndex: "9999",
                }}
              />
            ))}

          {(detail_loading || loading) && !refreshLoading ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "auto",
                justifyContent: "center",
                height: "100%",
              }}
            />
          ) : (
            <>
              {errorMessage && (
                <ShowDialog
                  title={"Error!"}
                  content={errorMessage}
                  cancelContent={"OK"}
                  cancelbtn={() => setErrorMessage("")}
                />
              )}

              <div
                className="persona_update_header"
                //remove style if autosave needs to show
                style={{ minHeight: "49px" }}
              >
                <div
                  className="persona_update_back"
                  onClick={() => navigate(PERSONA_CONFIGURATION_LIST)}
                >
                  <span className="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
              </div>

              <div className="persona_update_scroll_box">
                <div className="persona_update_item_main_box persona-margin-top">
                  <div className="persona_update_item_main_box_title">
                    Persona Name
                  </div>
                  <div className="radio-group">
                    {console.log(persona_list)}
                    {persona_list?.data
                      ?.slice()
                      ?.sort(
                        (a, b) =>
                          a.name !== null &&
                          b.name !== null &&
                          a.name.localeCompare(b.name)
                      )
                      .map((option) => (
                        <label
                          key={option.id}
                          className={`radio-button ${
                            selectedId === option.id ? "selected" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="persona"
                            value={option.id}
                            disabled={true}
                            checked={selectedId === option.id}
                            onChange={() => handleSelection(option.id)}
                          />
                          {option.name}
                        </label>
                      )) || <p>Loading...</p>}
                  </div>
                </div>

                <div className="persona_update_item_main_box persona-margin-top">
                  <div className="persona_update_item_main_box_title">
                    Personal Area/ DeptCode
                  </div>
                  <div>
                    <SelectorButton
                      key={"dept_" + selectedId}
                      codes={personaDepts}
                      selectedCodes={selectPersonaDepts}
                      onSelectionChange={handleDeptSelectionChange}
                    />
                  </div>
                </div>

                <div className="persona_update_item_main_box persona-margin-top">
                  <div className="persona_update_item_main_box_title">
                    Personal Sub Area/ SectCode
                  </div>
                  <div>
                    <SelectorButton
                      key={"sect_" + selectedId}
                      codes={personaSect}
                      selectedCodes={selectPersonaSect}
                      onSelectionChange={handleSectSelectionChange}
                    />
                  </div>
                </div>

                <div className="persona_update_item_main_box persona-margin-top">
                  <div className="persona_update_item_main_box_title">
                    PayArea
                  </div>
                  <div>
                    <SelectorButton
                      key={"payarea_" + selectedId}
                      codes={personaPayArea}
                      selectedCodes={selectPersonaPayArea}
                      onSelectionChange={handlePayAreaSelectionChange}
                    />
                  </div>
                </div>

                <div className="persona_update_item_main_box persona-margin-top">
                  <div className="persona_update_item_main_box_title">
                    Select permission(s)
                  </div>
                  <div>
                    {all_permissions_loading ? (
                      <Loader
                        type="infinite-spinner"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "40px",
                          margin: "auto",
                          justifyContent: "center",
                          height: "350px",
                        }}
                      />
                    ) : (
                      all_permissions.map((v) => (
                        <div className="permission-item">
                          <p className="permission-item-title">
                            {v.module_name}
                          </p>
                          <div className="permission-item-permission-group">
                            {v.permissions.map((pp) => (
                              <p>
                                <Checkbox
                                  label={pp.description + " (" + pp.name + ")"}
                                  onChange={(e) => handleSelected(e, pp)}
                                  checked={isChecked(pp)}
                                />
                              </p>
                            ))}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="persona_update_footer_main_box">
                <div className="persona_update_review_main_box">
                  <div
                    className="persona_update_footer_button_previous"
                    onClick={handleCancel}
                  >
                    Cancel
                  </div>
                  <div
                    className={`persona_update_footer_button`}
                    // className={`persona_update_footer_button ${
                    //   !isButtonEnabled ? "disabled" : ""
                    // }`}
                    onClick={handleSubmitRecord} // Prevent click if disabled
                    // onClick={isButtonEnabled ? handleSubmit : undefined} // Prevent click if disabled
                  >
                    Submit
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
