export const ConfigData = [
  {
    id: "name",
    name: "Persona Name",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "persona_dept_codes",
    name: "Personal Area/ DeptCode ",
    isShow: true,
    width: "250px",
    filter: true,
  },
  {
    id: "persona_sect_codes",
    name: "Personal Sub Area/ SectCode",
    isShow: true,
    width: "250px",
    filter: true,
  },
  {
    id: "persona_pay_areas",
    name: "Pay Area",
    isShow: true,
    width: "250px",
    filter: true,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Update"],
    permission: {
      ["View Details"]: {
        id: "View Details",
        permission: "admin_tools.access_persona",
      },
      Update: {
        id: "Update",
        permission: "admin_tools.add_edit_persona",
      },
    },
  },
];

export const dropdownConfig = [
];

export const statusConfig = [
 
];

export const DefaulSorting = {
  id: "name",
  name: "Persona Name",
  isShow: true,
  width: "160px",
  filter: true,
  
};
