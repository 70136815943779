// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  CREATE_PERSONA_ENDPOINT,
  PERSONA_LISTING_ENDPOINT,
  GET_PERSONA_DEPT_ENDPOINT,
  GET_PERSONA_SECT_ENDPOINT,
  GET_PERSONA_EMP_SGRP_ENDPOINT,
  GET_PERSONA_PAY_AREA_ENDPOINT,
  AUDIT_ENDPOINT,

} = config.api_endpoint;

const initialState = {
  loading: false,
  persona_list: [],
  error: null,
  next_key: {},
  detail_loading: false,
  detail_data: {},
  detail_error: null,
  audit_data: [],
  audit_loading: false,
  audit_error: null,
  persona_dept: [],
  persona_dept_error: null,
  persona_sect: [],
  persona_sect_error: null,
  persona_emp_sgrp: [],
  persona_emp_sgrp_error: null,
  persona_pay_area: [],
  persona_pay_area_error: null,
};

export const fetchAllPersona = createAsyncThunk(
  "persona/fetchAllPersona",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${CREATE_PERSONA_ENDPOINT}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchPersonaDept = createAsyncThunk(
  "persona/fetchPersonaDept",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${GET_PERSONA_DEPT_ENDPOINT}`;
      const results = await api.get(url, {}, `Bearer ${token}`);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchPersonaSect = createAsyncThunk(
  "persona/fetchPersonaSect",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${GET_PERSONA_SECT_ENDPOINT}`;
      const results = await api.get(url, {}, `Bearer ${token}`);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchPersonaPayArea = createAsyncThunk(
  "persona/fetchPersonaPayArea",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${GET_PERSONA_PAY_AREA_ENDPOINT}`;
      const results = await api.get(url, {}, `Bearer ${token}`);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchPersonaEmpSGRP = createAsyncThunk(
  "persona/fetchPersonaEmpSGRP",
  async (query, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${GET_PERSONA_EMP_SGRP_ENDPOINT}`;
      const results = await api.get(url, {}, `Bearer ${token}`);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchPersonaDetailById = createAsyncThunk(
  "persona/fetchPersonaDetailById",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        CREATE_PERSONA_ENDPOINT + "/" + id,
        {},
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const createAndUpdatePersona = createAsyncThunk(
  "persona/createAndUpdatePersona",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        CREATE_PERSONA_ENDPOINT + "/" + id,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const fetchPersonaAudit = createAsyncThunk(
  "cares/fetchPersonaAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=persona&source_id=${id}`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const personaSlice = createSlice({
  name: "personaSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonaDetailById.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetchPersonaDetailById.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(fetchPersonaDetailById.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(fetchAllPersona.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPersona.fulfilled, (state, action) => {
        state.loading = false;
        state.persona_list = action.payload;
      })
      .addCase(fetchAllPersona.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPersonaDept.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPersonaDept.fulfilled, (state, action) => {
        state.loading = false;
        state.persona_dept = action.payload.data;
      })
      .addCase(fetchPersonaDept.rejected, (state, action) => {
        state.loading = false;
        state.persona_dept_error = action.error.message;
      })

      .addCase(fetchPersonaSect.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPersonaSect.fulfilled, (state, action) => {
        state.loading = false;
        state.persona_sect = action.payload.data;

      })
      .addCase(fetchPersonaSect.rejected, (state, action) => {
        state.loading = false;
        state.persona_sect_error = action.error.message;
      })

      .addCase(fetchPersonaEmpSGRP.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPersonaEmpSGRP.fulfilled, (state, action) => {
        state.loading = false;
        state.persona_emp_sgrp = action.payload.data;
      })
      .addCase(fetchPersonaEmpSGRP.rejected, (state, action) => {
        state.loading = false;
        state.persona_emp_sgrp_error = action.error.message;
      })

      .addCase(fetchPersonaPayArea.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPersonaPayArea.fulfilled, (state, action) => {
        state.loading = false;
        state.persona_pay_area = action.payload.data;

      })
      .addCase(fetchPersonaPayArea.rejected, (state, action) => {
        state.loading = false;
        state.persona_pay_area_error = action.error.message;
      })


      .addCase(fetchPersonaAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchPersonaAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchPersonaAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
  },
});



const PersonaReducer = personaSlice.reducer;
export default PersonaReducer;
