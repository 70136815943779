export const ConfigData = [
  {
    id: "reference_no",
    name: "iLink Case Ref No.",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "operation_date",
    name: "Operation Date",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "service_no",
    name: "Service No.",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "direction",
    name: "Direction",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "day_type",
    name: "Day Type",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "user_id",
    name: "Employee ID",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "request",
    name: "Request",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "created_on",
    name: " Created On. ",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "ticket_status",
    name: "Ticket Status",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "status",
    name: "Current Status",
    isShow: true,
    width: "200px",
    filter: false,
    // type: "action",
    // action_type: "dropdown",
    // action_identifier: "handleStatusChange",
    // action_show_on: [
    //   {
    //     show_checker_id: "status",
    //     show_condition: [
    //       "valid",
    //       "invalid",
    //       "Review in Progress",
    //       "Not Supported",
    //     ],
    //     // role: ["DR Safe Staff"],
    //     // role: ["System Owner"],
    //     // permission: ["roster.view_monthly_ot"],
    //     permission: [
    //       "runtime_feedback.update_valid_invalid_status_runtime_feedback",
    //     ],
    //     action_value: ["Review in Progress", "Not Supported"],
    //   },
    // ],
    // action_hide_on_show_id: "status",
  },
  {
    id: "updated_by.name",
    name: "Status by",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "depot",
    name: "Depot",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "ig",
    name: "IG",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "160px",
    action: ["View Details", "Update Status"],
    // role: {
    //   target_action: ["Update Status"],
    //   role_checker: {
    //     ["runtime_feedback.update_valid_invalid_status_runtime_feedback"]: {
    //       status: ["valid", "invalid", "Review in Progress", "Not Supported"],
    //       action: ["Update Status"],
    //       targeted_data: "status",
    //     },
    //     ["runtime_feedback.update_pending_status_runtime_feedback"]: {
    //       status: ["submitted"],
    //       action: ["Update Status"],
    //       targeted_data: "status",
    //     },
    //   },
    // },
    permission: {
      ["Update Status"]: {
        id: "Update Status",
        permission: "runtime_feedback.update_runtime_feedback_status",
      },
      // Duplicate: {
      //   id: "Duplicate",
      //   permission: "happening.create_happening_article",
      // },
      ["View Details"]: {
        id: "View Details",
        permission: "runtime_feedback.access_runtime_feedback_portal",
      },
      // Unpublish: {
      //   id: "Unpublish",
      //   permission: "happening.update_happening_article_status",
      // },
      // Delete: {
      //   id: "Delete",
      //   permission: "happening.delete_happening_article",
      // },
    },
    isshowAction: {
      item: "status",
      children: [
        {
          id: "Update Status",
          value: "Update Status",
          notShowCondition: "Review in Progress",
        },
        {
          id: "Update Status",
          value: "Update Status",
          notShowCondition: "Invalid",
        },
        {
          id: "Update Status",
          value: "Update Status",
          notShowCondition: "Not Supported",
        },
        {
          id: "Update Status",
          value: "Update Status",
          notShowCondition: "Valid",
        },
      ],
    },
  },
];
