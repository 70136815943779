import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";

const {
  RUNTIME_LISTING_ENDPOINT,
  RUNTIME_DETAIL_ENDPOINT,
  AUDIT_ENDPOINT,
  RUNTIME_BUS_SERVICE_ENDPOINT,
  RUNTIME_STATUS_UPDATE_ENDPOINT
} = config.api_endpoint;

const initialState = {
  loading: false,
  auditLoading: false,
  serviceLoading: false,
  error: null,
  listing_data: [],
  detail_data: {},
  audit_data: [],
  bus_service_data: [],
  update_data: {}
};

export const fetchRuntimeListing = createAsyncThunk(
  "runtime/fetchRuntimeListing",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        RUNTIME_LISTING_ENDPOINT,
        { ..._, page_size: 99999999 },
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchRuntimeDetail = createAsyncThunk(
  "runtime/fetchRuntimeDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${RUNTIME_DETAIL_ENDPOINT}${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchRuntimeAudit = createAsyncThunk(
  "runtime/fetchRuntimeAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${AUDIT_ENDPOINT}?table_name=fob-runtime&source_id=${id}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchRuntimeBusServiceNo = createAsyncThunk(
  "runtime/fetchRuntimeBusServiceNo",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        `${RUNTIME_BUS_SERVICE_ENDPOINT}`,
        {},
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchRuntimeStatusUpdate = createAsyncThunk(
  "runtime/fetchRuntimeStatusUpdate",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.put(
        `${RUNTIME_STATUS_UPDATE_ENDPOINT}`,
        JSON.stringify({items: params}),
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const runtimeSlice = createSlice({
  name: "runtime",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRuntimeListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRuntimeListing.fulfilled, (state, action) => {
        state.loading = false;
        state.listing_data = action.payload.data;
      })
      .addCase(fetchRuntimeListing.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchRuntimeDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRuntimeDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail_data = action.payload.data;
      })
      .addCase(fetchRuntimeDetail.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      })
      .addCase(fetchRuntimeAudit.pending, (state) => {
        state.auditLoading = true;
      })
      .addCase(fetchRuntimeAudit.fulfilled, (state, action) => {
        state.auditLoading = false;
        state.audit_data = action.payload.data;
      })
      .addCase(fetchRuntimeAudit.rejected, (state, action) => {
        state.auditLoading = true;
        state.error = action.err.message;
      })
      .addCase(fetchRuntimeBusServiceNo.pending, (state) => {
        state.serviceLoading = true;
      })
      .addCase(fetchRuntimeBusServiceNo.fulfilled, (state, action) => {
        state.serviceLoading = false;
        state.bus_service_data = action.payload.data;
      })
      .addCase(fetchRuntimeBusServiceNo.rejected, (state, action) => {
        state.serviceLoading = true;
        state.error = action.err.message;
      })
      .addCase(fetchRuntimeStatusUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRuntimeStatusUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.update_data = action.payload.data;
      })
      .addCase(fetchRuntimeStatusUpdate.rejected, (state, action) => {
        state.loading = true;
        state.error = action.err.message;
      });
  },
});

const runtimeReducer = runtimeSlice.reducer;
export default runtimeReducer;
