import React from "react";
import CrossIcon from "../../assets/common/Cross_black.svg";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const ShowDialog = (props) => {
  return (
    <Dialog>
      <div style={{ position: "relative" }}>
        <div
          className="Dialog_cross_button"
          onClick={() => props.cancelbtn && props.cancelbtn()}
        >
          <img src={CrossIcon} />
        </div>
      </div>
      <div
        className="Dialog_main_box"
        style={props.mainBoxStyle || {}} // Provide a default empty object
      >
        <div className="Dialog_detail_box">
          <div className="Dialog_detail_title" style={{ textAlign: "center" }}>
            {props.title ? props.title : ""}
          </div>
          {props.content && (
            <div className="Dialog_detail_subTitle">
              {props.content ? props.content : ""}
            </div>
          )}
          {props.dropdown && (
            <div className="Dialog_detail_subTitle">
              {props.dropdownContent && (
                <div className="Dialog_detail_subTitle_content">
                  {props.dropdownContent ? props.dropdownContent : ""}
                </div>
              )}
              <DropDownList
                style={{ height: "48px" }}
                data={props.dropdownData ? props.dropdownData : []}
                onChange={(e) => {
                  props.dropdownOnChange && props.dropdownOnChange(e.value);
                }}
                value={props.dropdownValue ? props.dropdownValue : ""}
              />
            </div>
          )}
        </div>
        <div className="Dialog_button_box">
          <div
            className="Dialog_button_cancel"
            onClick={() => props.cancelbtn() && props.cancelbtn()}
          >
            {props.cancelContent ? props.cancelContent : ""}
          </div>
          {props.confrimContent && (
            <div
              className="Dialog_button_confirm"
              onClick={() => props.confrinbtn() && props.confrinbtn()}
            >
              {props.confrimContent ? props.confrimContent : ""}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
