import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const Filter = (props) => {
  return (
    <div className="runtime_filter_main_box">
      <div className="runtime_filter_child_box">
        <div className="runtime_filter_title">iLink Case Ref No.</div>
        <div className="runtime_filter_textBox">
          <TextBox
            placeholder="Please input"
            value={props.filterData.reference_no}
            onChange={(e) =>
              props.setFilterData({
                ...props.filterData,
                reference_no: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="runtime_filter_child_box">
        <div className="runtime_filter_title">Service No.</div>
        <div className="runtime_filter_textBox">
          <AutoComplete
            data={props.service_data}
            dataItemKey={"service_no"}
            textField="service_no"
            placeholder="Please input"
            value={props.filterData.service_no}
            onChange={(e) =>
              props.setFilterData({
                ...props.filterData,
                service_no: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="runtime_filter_child_box">
        <div className="runtime_filter_title">Employee ID</div>
        <div className="runtime_filter_textBox">
          <TextBox
            placeholder="Please input"
            value={props.filterData.bc_no}
            onChange={(e) =>
              props.setFilterData({
                ...props.filterData,
                bc_no: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="runtime_filter_child_box">
        <div className="runtime_filter_title">Request</div>
        <div className="runtime_filter_dropdown">
          <DropDownList
            size={"large"}
            data={["First Bus/ Last Bus", "Whole Day Runtime"]}
            className="runtime_filter_dropdown_item"
            value={props.filterData.request}
            onChange={(e) =>
              props.setFilterData({
                ...props.filterData,
                request: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="runtime_filter_child_box">
        <div className="runtime_filter_title">Current Status</div>
        <div className="runtime_filter_dropdown">
          <DropDownList
            data={["Valid", "Invalid", "Submitted"]}
            value={props.filterData.current_status}
            size={"large"}
            className="runtime_filter_dropdown_item"
            onChange={(e) =>
              props.setFilterData({
                ...props.filterData,
                current_status: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className="runtime_filter_child_box" style={{ gap: "8px" , maxWidth: "110px"}}>
        <div
          className="runtime_filter_clear_button"
          onClick={() =>
            props.setFilterData({
              ...props.filterData,
              reference_no: "",
              service_no: "",
              bc_no: "",
              request: "",
              current_status: "",
            })
          }
        >
          Clear Filter
        </div>
        {/* <div
          className="runtime_filter_approve_button"
          onClick={() => props.handleChangeStatus()}
        >
          Change Status
        </div> */}
      </div>
    </div>
  );
};
